import React, { ReactElement, useState, useEffect, LegacyRef, ReactNode } from 'react';
import cx from 'classnames';
import * as styles from './index.module.scss';

export interface Props {
	/**
	 * ForwardRef
	 */
	forwardRef?: LegacyRef<HTMLInputElement> | undefined;
	/**
	/**
	 * Input id
	 */
	id?: string;
	/**
	 * Default value
	 */
	defaultValue?: boolean;
	/**
	 * Callback for change
	 */
	onUpdate?: (value: boolean) => void;
	/**
	 * Classname to get merged
	 */
	className?: string;

	/**
	 * The theme
	 */
	theme?: string;

	/**
	 * Children to render
	 */
	children?: ReactNode;
}

export default function Checkbox({
	id,
	forwardRef,
	onUpdate,
	className,
	children,
	theme,
	defaultValue = false
}: Props): ReactElement {
	const [checked, setChecked] = useState(defaultValue);

	useEffect(() => {
		if (onUpdate) onUpdate(checked);
	}, [checked]);
	const color = theme === 'night' ? 'white' : 'black';

	return (
		<div className={cx(styles.root, checked && styles.checked, className, theme)}>
			<input
				ref={forwardRef}
				id={id}
				name={id}
				className={styles.element}
				type="checkbox"
				checked={checked}
				onChange={(event: React.ChangeEvent<HTMLInputElement>): void => setChecked(event.target.checked)}
			/>
			<span className={cx(styles.visual)} style={{color: color}}>
				<svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M6.11538 11.0023L8.04102 9.04309L1.92767 2.82331L0.00203279 4.78248L6.11538 11.0023Z"
						fill={color}
					/>
					<path
						d="M4.18764 9.03962L6.11328 10.9988L14.9978 1.95961L13.0721 0.000447484L4.18764 9.03962Z"
						fill={color}
					/>
				</svg>
			</span>
			{children && (
				<label className={styles.label} htmlFor={id}>
					{children}
				</label>
			)}
		</div>
	);
}
