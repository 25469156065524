import { Price } from '@Src/components/Price';
import Text from '@Src/components/Text';
import React, { ReactElement } from 'react';
import { usePageTheme, useApp } from '@Context/AppContext';	
import cx from 'classnames';
import * as styles from './index.module.scss';

interface State {}

interface Subscription {
	fee: number;
	period: string;
}

interface OrderRow {
	name: string;
  vat: number;
	price: number;
	recurring?: Subscription;
}

interface TotalVat {
	vatRate: number;
	total: number;
}

interface Order {
	rows: [OrderRow];
	vatRates: [TotalVat];
}

interface Props {
	order?: Order;
  showTax?: boolean;
}

const OrderSummary = ({ order, showTax }: Props): ReactElement<Props> => {
	const { mode } = useApp();
	let tableCssClasses = [styles.tableOrderSummary];
	if (mode == 'night') {
		tableCssClasses.push(styles.tableOrderSummaryNight);
	}
	return (
		<table className={cx(tableCssClasses)}>
			{showTax && <thead>
				<tr>
					<td>
						<Text htmlText={'Item'} />
					</td>
					<td>
						<Text htmlText={'Price'} />
					</td>
				</tr>
			</thead>}
			<tbody>
				{order?.rows?.map((item) => (
					<>
						<tr>
							<td style={{ width: '50%' }}>
								<Text htmlText={item.name} />
							</td>
							<td style={{ width: '50%' }}>
								<Price amount={item.price} currency={'EUR'} />
							</td>
						</tr>
						{item.recurring && false ? (
							<tr>
								<td style={{ width: '50%' }}>
									<Text
										htmlText={`
											(Incl. Additional ${item.recurring?.period} €${item.recurring?.fee.toLocaleString()} deal)
										`}
									/>
								</td>
								<td style={{ width: '50%' }}></td>
							</tr>
						) : null}
					</>
				))}
				{showTax && order?.vatRates?.map((vatRate) => (
					<tr>
						<td>
							<Text htmlText={`${vatRate.vatRate} % VAT`} />
						</td>
						<td>
							<Price amount={vatRate.total} currency={'EUR'} />
						</td>
					</tr>
				))}
				{showTax && <tr className="sum">
					<td style={{width: '50%'}}>
						<Text htmlText={`Digital distribution 1 year`} />
					</td>
					<td>
						<Price currency="EUR" amount={order?.rows?.reduce((t, t2) => t + (t2.price + t2.vat), 0) || 0} />
					</td>
				</tr>}
			</tbody>
		</table>
	);
};

export default OrderSummary;
