import React, { ReactElement, useMemo, useCallback, useState, useEffect, useRef } from 'react';
import axios from 'axios';

import cx from 'classnames';
import Input from '@UI/Input';
import Checkbox from '@UI/Checkbox';
import BaseButton from '@UI/BaseButton';
import Select from '@UI/Select';
import Link from '@UI/Link';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { graphql, useStaticQuery } from 'gatsby';
import { navigate } from 'gatsby';
import { createLead, createStripeCheckoutSession, createUserByEmail } from '../../../actions/aloaded';
import { Price } from '@Src/components/Price';
import Text from '@Src/components/Text';
import { sendSignInLinkToEmail } from 'firebase/auth';
import * as styles from './index.module.scss';
import { QueryParams } from '@Src/types/models';
import { useApp } from '@Src/context/AppContext';
import OrderSummary from '../OrderSummary';

export interface FormData {
	email: string;
	firstname: string;
	lastname: string;
	company: string;
	selectDummy: string;
}

export interface ResponseData {
	data: {
		inlineMessage: string;
	};
}

export interface Props {
	onResponse?: (value: string) => void;
}

const ECOMMERCE = {
  value: 200.00,
  currency: 'EUR',
  payment_type: 'Credit Card',
  items: [
    {
      name: 'Distribution+',
      id: '123',
      price: 200.00,
      brand: 'ALOADED',
      category: 'Service',
      quantity: 1
    }
  ]
}

const Form = ({ onResponse }: Props): ReactElement => {
	const [postError, setPostError] = useState<boolean>(false);
  const [queryParams, setQueryParams] = useState<QueryParams>({})
	const [submit, setSubmit] = useState(false);
	const [email, setEmail] = useState<string | null>(null);
	const [isEmailInvalid, setInvalidEmail] = useState(false);
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [labelName, setLabelName] = useState('');

	const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = useState(false);
  const [notAcceptedTermsAndConditionsError, setNotAcceptedTermsAndConditionsError] = useState(false)
  const formEl = useRef(null)
  const { mode } = useApp() 
  let query = new URLSearchParams
  if (typeof window !== 'undefined') {
    query = new URLSearchParams(window.location.search)
  }
	let submitButtonCssClass = styles.submitButton;
	if (mode == "night") {
		submitButtonCssClass = styles.submitButtonNight;
	}
  
	const onSubmit = (event: Event) => {
		event.preventDefault();
    // @ts-ignore
    let form : HTMLFormElement = event.target
    if (!form.reportValidity()) {
      return
    }

    if (!acceptedTermsAndConditions) {
      setNotAcceptedTermsAndConditionsError(true)
      return
    }
		setPostError(false);
    setSubmit(true)
		setEmail(email);
 
    preparePayment()
    
		return false;
	};

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    const newQueryParams = {
      utm_campaign: query.get('utm_campaign'),
      utm_source: query.get('utm_source'),
      utm_medium: query.get('utm_medium'),
      utm_content: query.get('utm_content'),
      fbclid: query.get('fbclid'),
      referrer: document.referrer
    }
    console.log(newQueryParams)
    setQueryParams(newQueryParams)
    // @ts-ignore
    if (window.dataLayer instanceof Array) {
      // @ts-ignore
      window.dataLayer.push({ ecommerce: null });
      // @ts-ignore
      window.dataLayer.push({
        event: 'view_item',
        ecommerce: {
          ...ECOMMERCE
        }
      })
    }
  }, [])

  const isDebug = false // query.get("isDebug999") == 'true';
  console.log('isDebug', isDebug)

  const preparePayment = () => {
    createStripeCheckoutSession({
      firstName,
      lastName,
      labelName,
      query: queryParams
    }).catch(e => {
      console.error(e)
      setSubmit(false)
      setPostError(true)
    }).then((session) => {
      // @ts-ignore
      if (window.dataLayer instanceof Array) {
        // @ts-ignore
        window.dataLayer.push({
          event: 'begin_checkout',
          ecommerce: {
            transaction_id: session.id,
            ...ECOMMERCE
          },
          eventCallback: () => {
            window.location.href = session.url
          },
        })
      }
    })
  }
  let classNames = [styles.root]
  // @ts-ignore
  if (mode === 'dark' || mode === 'night') {
    classNames.push(styles.rootDark)
  }

  const order = {
    rows: [
      {
        sku: 100,
        name: 'Digital Distribution (1 Year)',
        price: isDebug ? 8.00 : 200.00,
        vatRate: 25.00,
        vat: isDebug ? 8.00 : 50.00,
        qty: 1,
        currency: 'eur',
        recurring: {
          period: 'year',
          fee: 100.00,
          vatRate: 25.00,
          vat: 25.00
        }
      }
    ],
    vatRates: [
      {
        vatRate: 25.00,
        total: 50.00
      }
    ]
  }
	return (
    <div className={cx(classNames)}>
      {/* @ts-ignore */}
      <form ref={formEl} onSubmit={onSubmit} className={cx(classNames)}>
        {/* @ts-ignore */}
        <OrderSummary showTaxes={false} order={order} />
        <table className={cx(styles.table)}>
          <tbody>
            <tr>
              <td>
                <Input
                  id="firstName"
                  required
                  inputType='text'
                  className={cx(styles.input)}
                  label={"First Name"}
                  onUpdate={(value) => setFirstName(value)}
                  theme={mode}
                />
              </td>
              <td>
                <Input
                  id="lastName"
                  required
                  inputType='text'
                  className={cx(styles.input)}
                  label={"Last Name"}
                  onUpdate={(value) => setLastName(value)}
                  theme={mode}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <Input
                  id="labelName"
                  required
                  inputType='labelName'
                  className={cx(styles.input)}
                  label={"Label name"}
                  onUpdate={(value) => setLabelName(value)}
                  theme={mode}
                />
              </td>
            </tr>
            {/*<tr>
              <td colSpan={2}>
                <Input
                  id="email"
                  required
                  inputType='email'
                  className={cx(styles.input)}
                  label={"E-mail"}
                  onUpdate={(value) => setEmail(value)}
                  theme={mode}
                />
              </td>
            </tr>*/}
          </tbody>
        </table>
        <div style={{ padding: 20 }}>
        <Text
          theme={mode}
        >The digital distribution agreement between the Company (which details are set out above on this order confirmation) and ALOADED, regarding ALOADED’s provision of digital distribution services for the Company’s recordings, consists of this order confirmation and the attached <a href="/terms-and-conditions" target="_blank">Terms and Conditions</a> including all attachments thereto (together the "Agreement").</Text>
        </div>
        <table className={cx(styles.table)}>
          <tbody>
            <tr>
              <td>
                <label htmlFor="acceptTermsAndConditions" className={styles.checkBox}>
                  <Checkbox theme={mode} onUpdate={(value) => {
                    setAcceptedTermsAndConditions(value)
                    setNotAcceptedTermsAndConditionsError(false)
                  }} />
                </label>
	      </td>
              <td valign="top">
                <Text
                  theme={mode}
                >By checking this box, the Company accepts the terms of this Agreement and confirms that it has reviewed and understood the terms hereof.</Text>               
                {notAcceptedTermsAndConditionsError && <b
                  style={{
                    color: '#ff0000',
                    fontSize: 40
                  }}
                  // @ts-ignore
                  theme={mode}
                 >*You must accept our terms and conditions before you continue</b>}
              </td>
            </tr>
          </tbody>
        </table>
        <BaseButton
          className={submitButtonCssClass}
          isLoading={false}
          isConfirm={true}
          disabled={false}
          style={{
            height: '120.903px'
          }}
          buttonType="submit"
          link={{
            url: '',
            id: 'submit',
            target: '_modal',
            label: submit ? 'Submitting your details...' : 'Next: Payment',
            primary: true
          }}
        />
        <div style={{padding: 20}}>
        </div>
      </form>
    </div>
  );
};

export default Form;
